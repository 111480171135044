<template>
      <div class="container-custom">
	    <div class="row color-invoice">
      <div class="col-md-12">

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
          <div style="width:50%;float:left;">
             <img width="150px"  src="/media/logos/INBA-logo.png" />
            <br> 32 Kallang Pudding Road 
                    # 01-01, Elite Industrial Building I,
                    Singapore 349313
            <br> +65 6679 5757
            <br>info@indoboxasia.com
          </div>
          <div style="width:30%;padding-top: 10px;float:right;">
            <br>
            <strong>RUNSHEET DETAILS </strong><br>
             Singapore  <br>
             Phone: +6569505915 <br>
             Date : {{new Date(obj.serviceObj.assigning_date).toLocaleDateString("en-us", {
                              year: "numeric",
                              month: "short",
                              day: "numeric"
                            })}}<br>
          </div>
          </div>
        </div>
        <hr />
            <div class="table-responsive">
              <table class="table table-striped table-bordered" style="font-size:12px">
                <thead>
                  <tr>
                    <th>Stop No.</th>
                    <th>AWB No.</th>
                    <th>Sender</th>
                    <th>Receiver</th>
                    <th>Service</th>
                    <th>Instructions</th>
                    <th>Pieces</th>
                    <th>Weight</th>
                    <th>Signature</th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in obj.shipments" :key="index">
                        <td></td>
                        <td>{{obj.awbNo}}</td>
                        <td>
                            <span v-if="item.type == 1">
                                <b>{{ item.prealert_sender_name }}</b>
                            </span> 
                            <span v-if="item.type == 2">
                                <b>{{ item.sender_name }}</b>
                            </span>
                        </td>
                        <td>
                            <b>{{item.receiver_name}}</b><br/>
                            {{item.receiver_phone}}<br/>
                            {{ item.receiver_street_address }},
                            {{ item.receiver_city }},{{ item.receiver_state }} ,
                            {{ item.receiver_country }} -
                            {{ item.receiver_zipcode }}
                        </td>
                        <td>{{obj.serviceObj.service_type}}</td>
                        <td>{{item.instructions}}</td>
                        <td>{{item.total_pieces}}</td>
                        <td>{{item.total_weight}}</td>
                        <td style="background-color: white !important;"></td>
                    </tr>
                </tbody>
              </table>
            </div>

          <div style="clear:both;"></div>
          <div style="width:50%;float:left;">
            <br>
            <strong> Supervisor Signature: </strong><br>
            <strong> Date: </strong>
          </div>
          <div style="width:50%;padding-top: 10px;float:right;">
            <br>
            <strong> Courier Signature: </strong><br>
            <strong> Date: </strong>
          </div>
      </div>
    </div>
</div>
</template>
<style scoped>
    @media print {
        @page {  size: auto; margin: 10px;  }
        .container-custom {
            padding-right: unset !important;
            padding-left: unset !important;
            margin-right: unset !important;
            margin-left: unset !important;
            font-size: 14pt !important;
            font-weight: normal;
        }
    }
</style>
<style >
    body{
        background: white;
    }
    .container-custom {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    @media only screen and (min-width: 768px){
        .container-custom {
            width: 750px;
        }
    }

    @media only screen and (min-width: 992px){
        .container-custom {
            width: 970px;
        }
    }

    @media only screen and (min-width: 1200px){
         .container-custom {
            width: 1170px;
        }
    }
    
</style>
<script>
export default {
  data() {
    return {
        obj: this.$route.params.obj
    };
  },
  mounted() {
  },
  components: {
  },
  methods: {
   
  },
};
</script>